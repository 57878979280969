import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Entry, EntryType, Grade, Height } from "../../types/entry";
import { Box } from "@mui/system";

import useWindowDimensions from "../../utils/useWindowDimensions";
import axios from "axios";
import Results from "../Results";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm/component";
import { Link, useParams } from "react-router-dom";
import ShowDetails from "../../types/showDetails";
import EntryManagement from "../EntryManagement/component";
import Brecon from "../../images/christmas.gif";

function formatDate(date: Date) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-GB", options as any);
  const day = date.getDate();
  const suffix = getDaySuffix(day);
  return formattedDate.replace(day.toString(), day + suffix);
}

function getDaySuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

const styles = (theme: Theme) => ({
  logo: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

const date = new Date("2023-12-19");
const formattedDate = formatDate(date);
console.log(formattedDate);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  // "pk_test_51MWjvjCAoxTMQpnDgEMlrCJWeFQW5aLGkU2qiJiocfyVeykJWmblo9N9BbouaOUF0lbXdEzWV3HpnSdnXJ3fY6Hq00zbHH0fge"
  "pk_live_51MWjvjCAoxTMQpnD5U9G2bxVn4gYXJlrHbFJIVktWcv356989K261ZJwjpBpLcgBWFHpSYVs5oY5hpIRFp9PWyVb002U43hPTT"
);

const code = "";

const emailReg =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const EnterPage = () => {
  const classes = styles(useTheme());

  const [entriesRemaining, setEntriesRemaining] = useState<number>(1);
  const [stripeOptions, setStripeOptions] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  // const { showId } = useParams();
  const showId = "35";

  const [entries, setEntries] = useState<Entry[]>([]);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [voucher, setVoucher] = useState<string>("");
  const [processingFee, setProcessingFee] = useState<string>("0.00");
  const [paymentType, setPaymentType] = useState<number>(0);
  const [refund, setRefund] = useState<boolean>(false);
  const [currentlyPaid, setCurrentlyPaid] = useState<string>("0.00");

  const { width } = useWindowDimensions();

  const [access, setAccess] = useState<string>("");
  const [activeEntry, setActiveEntry] = useState<number>(0);
  const [enterConfirmationOpen, setEnterConfirmationOpen] =
    useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);

  const [showDetails, setShowDetails] = useState<ShowDetails>(
    {} as ShowDetails
  );

  useEffect(() => {
    getShowDetails();
  }, []);

  useEffect(() => {
    const getEntriesRemaining = async () => {
      const response = await axios.get(
        "https://api.easyagility.co.uk/remaining-entries?show_id=" + showId
      );

      setEntriesRemaining(response.data.remaining_entries);
    };

    getEntriesRemaining();
  }, []);

  // useEffect(() => {
  //   updateWithVoucher(voucher);
  // }, [voucher]);

  // const updateWithVoucher = async (voucher: string) => {
  //   setLoading(true);

  //   if (voucher === "EpFr33") {
  //     setProcessingFee("0.00");
  //   } else if (voucher === "Pho3nixM3mber") {
  //     setProcessingFee(getProcessingFeeDiscounted());
  //     await updateStripeDiscounted();
  //   } else {
  //     await updateStripe();
  //   }

  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // };

  const addEntry = () => {
    const newEntries = [...entries];

    newEntries.push({
      classes: [174, 175, 176],
      handler_name: "",
      handler_first_name: "",
      handler_last_name: "",
      dog_name: "",
      height: "",
      grade: Grade.One,
      entry_type: EntryType.Full,
      nfc_dogs: [],
      address: "",
    } as Entry);

    setEntries(newEntries);
    setActiveEntry(newEntries.length - 1);
  };

  const previousEntry = () => {
    setActiveEntry(activeEntry - 1);
  };

  const nextEntry = () => {
    setActiveEntry(activeEntry + 1);
  };

  const handleChange = (index: number, e: any) => {
    if (e.target.name === "height") {
      handleRemoveClass(index, 51);
      handleRemoveClass(index, 52);
    }

    const newEntries = [...entries] as any[];
    newEntries[index][e.target.name] = e.target.value;
    setEntries(newEntries);
  };

  const handleAddClass = (index: number, classId: number) => {
    const newEntries = [...entries] as any[];

    const entry = newEntries[index];

    if (!entry.classes.includes(classId)) {
      entry.classes.push(classId);
      setEntries(newEntries);
    }
  };

  const handleRemoveClass = (index: number, classId: number) => {
    const newEntries = [...entries] as any[];

    const entry = newEntries[index];

    entry.classes = entry.classes.filter((c: number) => c !== classId);

    setEntries(newEntries);
  };

  const enterShowClick = async () => {
    // setLoading(true);
    // const response = await axios.post(
    //   "https://api.easyagility.co.uk/stripe/payment-intent",
    //   {
    //     amount: Math.floor(parseFloat(getTotalWithServiceFess()) * 100),
    //   }
    // );
    // console.log(response.data);
    // setClientSecret(response.data.client_secret);
    // setStripeOptions({ clientSecret: response.data.client_secret });
    // setEnterConfirmationOpen(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
  };

  const updateStripeDiscounted = async () => {
    // const response = await axios.post(
    //   "https://api.easyagility.co.uk/stripe/payment-intent",
    //   {
    //     amount: Math.floor(
    //       parseFloat(getTotalWithServiceFeesDiscounted()) * 100
    //     ),
    //   }
    // );
    // console.log(response.data);
    // setClientSecret(response.data.client_secret);
    // setStripeOptions({ clientSecret: response.data.client_secret });
  };

  const updateStripe = async () => {
    // const response = await axios.post(
    //   "https://api.easyagility.co.uk/stripe/payment-intent",
    //   {
    //     amount: Math.floor(parseFloat(getTotalWithServiceFess()) * 100),
    //   }
    // );
    // console.log(response.data);
    // setClientSecret(response.data.client_secret);
  };

  const removeEntry = () => {
    const newEntries = [...entries];

    newEntries.splice(activeEntry, 1);

    setEntries(newEntries);
    setActiveEntry(0);
  };

  const getTotal = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += 8.5;
      // } else {
      //   amount += 16;
      // }
      amount += 12;
    }

    amount = amount - parseFloat(currentlyPaid);

    if (amount < 0) {
      setRefund(true);
    }

    return amount.toFixed(2);
  };

  const getTotalDiscounted = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += 8.5;
      // } else {
      //   amount += 16;
      // }
      amount += 12;
    }

    amount = amount - parseFloat(currentlyPaid);

    return amount.toFixed(2);
  };

  const getProcessingFee = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += 8.5;
      // } else {
      //   amount += 16;
      // }
      amount += 18;
    }

    amount = amount - parseFloat(currentlyPaid);

    return (amount * 0.025 + 0.25).toFixed(2);
  };

  const getProcessingFeeDiscounted = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += 8.5;
      // } else {
      //   amount += 16;
      // }
      amount += 12;
    }
    return (amount * 0.025 + 0.25).toFixed(2);
  };

  const getTotalWithServiceFess = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += 8.5;
      // } else {
      //   amount += 16;
      // }
      amount += 12;
    }

    amount = amount - parseFloat(currentlyPaid);

    amount += amount * 0.025 + 0.25;

    return amount.toFixed(2);
  };

  const getTotalWithServiceFeesDiscounted = (): string => {
    let amount = 0;

    for (const entry of entries) {
      // if (entry.classes.length < 4) {
      //   amount += entry.classes.length * 4.5;
      // } else {
      //   amount += 17;
      // }
      amount += 12;
    }

    amount += amount * 0.025 + 0.25;

    return amount.toFixed(2);
  };

  const getShowDetails = async () => {
    const response = await axios.get(
      "https://api.easyagility.co.uk/shows/" + showId
    );

    setShowDetails(response.data);
    setLoading(false);
  };

  const enterShow = async (description: string) => {
    const e = [...entries];

    try {
      for (const entry of e) {
        const nameParts = entry.handler_name.split(" ");

        entry.handler_last_name = nameParts.pop() || "";
        entry.handler_first_name = nameParts.join(" ");
      }
    } catch (e) {
      console.log(e);
      setDisabled(false);
      return;
    }

    await axios.post("https://api.easyagility.co.uk/entries", {
      email_address: email,
      total_owing:
        voucher === "EpFr33"
          ? "0.00"
          : voucher === "Pho3nixM3mber"
          ? getTotalDiscounted()
          : getTotal(),
      entries: entries,
      description: description,
      show_id: parseInt(showId || "0"),
    });

    setEnterConfirmationOpen(false);
    setSuccess(true);
    setDisabled(false);
  };

  return (
    <Box padding={3}>
      {loading ? (
        <CircularProgress />
      ) : access !== code ? (
        <div style={{ textAlign: "center" }}>
          <Results />
        </div>
      ) : success ? (
        <div style={{ textAlign: "center" }}>
          <Typography
            style={{ marginTop: "200px", marginBottom: "50px" }}
            variant="h6"
          >
            Thanks, we have now received your entry! Please Check Your Email For
            Confirmation. If you still haven't received confirmation, please
            check your junk email. If you need to change your entry details,
            please email matt@easyagility.co.uk and we will get back to you! You
            can also view your entry on the Easy Agility Hub by clicking the
            button below.
          </Typography>
          <Box marginTop={2} marginBottom={2}>
            <Button
              onClick={() => {
                window.open(
                  "https://hub.easyagility.co.uk",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              variant="contained"
            >
              Visit Easy Agility Hub
            </Button>
          </Box>
          <CheckCircleIcon
            htmlColor="green"
            fontSize="inherit"
            style={{ fontSize: "200" }}
          />
        </div>
      ) : enterConfirmationOpen ? (
        <Box marginTop={10} textAlign={"left"}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm This Entry
          </Typography>
          <TextField
            style={{
              width: "300px",
              marginBottom: "30px",
              marginTop: "30px",
              textAlign: "center",
            }}
            id={`discount-code`}
            name={"discount-code"}
            value={voucher}
            label="Voucher Code"
            fullWidth
            onChange={(e) => {
              setVoucher(e.target.value);
            }}
          />
          <Typography
            sx={{ textDecoration: "underline" }}
            id="modal-modal-description"
          >
            Fees
          </Typography>
          <Typography id="modal-modal-description">
            Show Entry: £
            {voucher === "EpFr33"
              ? 0
              : voucher === "Pho3nixM3mber"
              ? getTotalDiscounted()
              : getTotal()}
          </Typography>
          <Typography id="modal-modal-description">
            Show Processing Fee: £
            {voucher === "EpFr33"
              ? 0
              : voucher === "Pho3nixM3mber"
              ? getProcessingFeeDiscounted()
              : getProcessingFee()}{" "}
            (2.5% + 25p)
          </Typography>
          <Typography id="modal-modal-description">
            Total: £
            {voucher === "EpFr33"
              ? 0
              : voucher === "Pho3nixM3mber"
              ? getTotalDiscounted()
              : getTotalWithServiceFess()}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Payment Method</strong>
          </Typography>
          <Box marginTop={2}>
            {voucher === "EpFr33" ? (
              <>
                <>This Entry is Free</>
                <Button
                  onClick={() => setEnterConfirmationOpen(false)}
                  style={{ minWidth: "100%", marginTop: "30px" }}
                  variant="contained"
                  color="secondary"
                >
                  Back To Entries
                </Button>
                <Button
                  onClick={(e) => {
                    enterShow("free");
                  }}
                  style={{ minWidth: "100%", marginTop: "10px" }}
                  variant="contained"
                  disabled={!email.match(emailReg)}
                >
                  Pay and Confirm Entry
                </Button>
              </>
            ) : (
              <>
                {loading ? (
                  <>Loading Basket...</>
                ) : (
                  <>
                    {refund ? (
                      "You are owed a refund"
                    ) : voucher === "Pho3nixM3mber" ? (
                      <Elements stripe={stripePromise} options={stripeOptions}>
                        <>Voucher Applied</>
                        <CheckoutForm
                          enterShow={enterShow}
                          setEnterConfirmationOpen={setEnterConfirmationOpen}
                          disabled={!email.match(emailReg)}
                        />
                      </Elements>
                    ) : (
                      <Elements stripe={stripePromise} options={stripeOptions}>
                        <CheckoutForm
                          enterShow={enterShow}
                          setEnterConfirmationOpen={setEnterConfirmationOpen}
                          disabled={!email.match(emailReg)}
                        />
                      </Elements>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box marginTop={10}>
          <Box sx={{ textAlign: "center" }}>
            <Typography fontWeight="bold" fontSize={16}>
              {showDetails?.name || ""} at {showDetails?.venue || ""} on{" "}
              {formatDate(new Date(showDetails?.date)) || ""}
            </Typography>
            <Box textAlign={"center"} marginTop={3}>
              <img
                style={{ borderRadius: "20px" }}
                width="350"
                height="200"
                src={Brecon}
              />
            </Box>
            <Button
              sx={{ marginTop: 2, bgcolor: "#8f9c5d" }}
              variant="contained"
              color="success"
              onClick={() => {
                window.open(
                  showDetails?.schedule_url || "",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              View Schedule (PDF)
            </Button>
          </Box>

          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1" component="p">
              Judge: {showDetails?.judge || ""}
            </Typography>
            <Typography sx={classes.logo} variant="body1" component="p">
              {showDetails?.classes?.map((c) => c.name).join(", ")}
            </Typography>
            <Typography variant="body1" component="p">
              Entries Remaining: {entriesRemaining > 0 ? entriesRemaining : 0}
            </Typography>
          </Box>

          <>
            {entriesRemaining > 0 ? (
              <>
                <EntryManagement
                  showDetails={showDetails}
                  showId={parseInt(showId || "0")}
                  enterShowClick={enterShowClick}
                  handleChange={handleChange}
                  addEntry={addEntry}
                  removeEntry={removeEntry}
                  setEmailAddress={setEmail}
                  emailAddress={email}
                  entries={entries}
                  setEntries={setEntries}
                  handleAddClass={handleAddClass}
                  handleRemoveClass={handleRemoveClass}
                />
              </>
            ) : (
              <>
                <Box marginTop={10} textAlign={"center"}>
                  <Typography fontWeight="bold">
                    Sorry, this show has now closed for entries.
                  </Typography>
                </Box>
              </>
            )}
            {/* <Box marginTop={10} textAlign={"center"}>
              <Typography fontWeight="bold">
                Sorry, this show has now closed.
              </Typography>
            </Box> */}
          </>
        </Box>
      )}
    </Box>
  );
};

export default EnterPage;
