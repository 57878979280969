import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Entry, Grade, Height } from "../../../types/entry";
import { accountInfo } from "../component";
import axios from "axios";
import CardPayment from "../CardPayment/component";
import ShowDetails from "../../../types/showDetails";

type availableClasses = {
  id: number;
  name: string;
  judge_name: string;
};

const EntryComponent = ({
  editing,
  entry,
  entries,
  index,
  setIndex,
  addEntry,
  removeEntry,
  handleChange,
  handleRemoveClass,
  handleAddClass,
  totalEntries,
  accountInfo,
  showId,
  email,
  paid,
  setPaid,
  reference,
  setReference,
  showDetails,
  isMember,
  setIsMember,
}: {
  editing: boolean;
  entries: Entry[];
  entry: Entry;
  totalEntries: number;
  index: number;
  handleChange: (index: number, e: any) => void;
  handleRemoveClass: (index: number, classId: number) => void;
  handleAddClass: (index: number, classId: number) => void;
  setIndex: (index: number) => void;
  addEntry: () => void;
  removeEntry: (index: number) => void;
  accountInfo: accountInfo;
  showId: number;
  email: string;
  paid: boolean;
  setPaid: (paid: boolean) => void;
  reference: string;
  setReference: (reference: string) => void;
  showDetails: ShowDetails;
  isMember: boolean;
  setIsMember: (isMember: boolean) => void;
}) => {
  const [stage, setStage] = useState<number>(0);
  const [classVal, setClassVal] = useState<number>(1);
  const [availableClasses, setAvailableClasses] = useState<availableClasses[]>(
    []
  );
  const [total, setTotal] = useState<number>(0);
  const [processingFee, setProcessingFee] = useState<number>(0);
  const [discountCode, setDiscountCode] = useState<string>("");

  const getAvailableClasses = async () => {
    const response = await axios.get(
      `https://api.easyagility.co.uk/shows/${showId}/available-classes?height=${entry.height}&grade=${entry.grade}`
    );

    setAvailableClasses(response.data);
  };

  useEffect(() => {
    getAvailableClasses();
  }, [stage]);

  useEffect(() => {
    if (editing) setStage(14);
  }, [editing]);

  const calculatePricings = () => {
    let total = 0;

    for (entry of entries) {
      // if (isMember) {
      //   if (entry.classes.length === 4) {
      //     total += 16;
      //   } else {
      //     total += 8;
      //   }
      // } else {
      //   if (entry.classes.length === 4) {
      //     total += 17;
      //   } else {
      //     total += 9;
      //   }
      // }
      total += 12;
    }

    const processingFee = total * 0.025 + 0.25;
    setTotal(total);
    setProcessingFee(processingFee);
  };

  useEffect(() => {
    if (discountCode === "Pho3nixM3mb3r") {
      setIsMember(true);
    }
  }, [discountCode]);

  useEffect(() => {
    calculatePricings();
  }, [entries, isMember]);

  const preEnter = async () => {
    const e = [...entries];

    for (const entry of e) {
      const nameParts = entry.handler_name.split(" ");

      entry.handler_last_name = nameParts.pop() || "";
      entry.handler_first_name = nameParts.join(" ");
    }

    const response = await axios.post(
      "https://api.easyagility.co.uk/pre-enter",
      {
        email_address: email,
        total_owing: (total + processingFee).toFixed(2),
        show_fees: total.toFixed(2),
        entries: e,
        description: "pre-entry",
        show_id: showId,
        reference: reference,
        is_member: isMember,
      }
    );

    setReference(response.data.reference);
  };

  return (
    <Box>
      {stage === 0 && (
        <>
          <Typography fontWeight={"bold"}>Handler Name</Typography>
          <Typography marginTop={2}>
            The competitor who will be handling the dog. This can be you, or
            someone else. Please include their first and last names. 🏃‍♀️🏃
          </Typography>
          <Autocomplete
            options={accountInfo.handlers || []}
            id="controlled-demo"
            freeSolo
            value={entry.handler_name}
            onInputChange={(event: any, newValue: string | null) => {
              if (newValue) {
                handleChange(index, {
                  target: { value: newValue, name: "handler_name" },
                });
              }
            }}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                handleChange(index, {
                  target: { value: newValue, name: "handler_name" },
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                margin="dense"
                helperText="Handler's full name"
                id="handler"
              />
            )}
          />

          {index > 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginRight: 2,
                textTransform: "none",
                marginTop: 2,
                bgcolor: "#8f9c5d",
              }}
              onClick={() => {
                setIndex(index - 1);
                setStage(5);
              }}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            disabled={entry.handler_name.length === 0}
            onClick={() => {
              setStage(1);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 1 && (
        <>
          <Typography fontWeight={"bold"}>Dog's Name</Typography>
          <Typography marginTop={2}>
            The name of the dog who you will be competing with. This can just be
            the pet name of the dog! 🐶
          </Typography>
          <Autocomplete
            options={accountInfo.dogs?.map((d) => d.name) || []}
            freeSolo
            value={entry.dog_name}
            onInputChange={(event: any, newValue: string | null) => {
              if (newValue) {
                handleChange(index, {
                  target: { value: newValue, name: "dog_name" },
                });
              }
            }}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                handleChange(index, {
                  target: { value: newValue, name: "dog_name" },
                });

                const dog = accountInfo.dogs.find((d) => d.name === newValue);
                if (dog) {
                  if (dog.kc_name && dog.kc_name.length > 0) {
                    handleChange(index, {
                      target: { value: dog.kc_name, name: "kennel_club_name" },
                    });
                  }
                  if (dog.kc_number && dog.kc_number.length > 0) {
                    handleChange(index, {
                      target: {
                        value: dog.kc_number,
                        name: "kennel_club_number",
                      },
                    });
                  }
                  if (dog.height && dog.height.length > 0) {
                    handleChange(index, {
                      target: { value: dog.height, name: "height" },
                    });
                  }
                  if (dog.grade && dog.grade > 0) {
                    handleChange(index, {
                      target: { value: dog.grade, name: "grade" },
                    });
                  }
                  getAvailableClasses();
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                margin="dense"
                helperText="Dog's pet name"
                id="dog_name"
              />
            )}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(0);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.dog_name.length === 0}
            onClick={() => {
              setStage(2);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 2 && (
        <>
          <Typography fontWeight={"bold"}>Dog's Height</Typography>
          <Typography marginTop={2}>
            The height of the dog you are competing with. 600 = large, 500 =
            intermediate, 400 = medium, 300 = small, 200/300 = anysize. 📏
          </Typography>
          <FormControl sx={{ marginTop: 2 }} fullWidth>
            <InputLabel id={`height-label-${index}`}>Height</InputLabel>
            <Select
              labelId={`height-label-${index}`}
              id={`height-select-${index}`}
              value={entry.height}
              label="Height"
              name="height"
              onChange={(e) => handleChange(index, e)}
            >
              <MenuItem value={Height.Large}>600</MenuItem>
              <MenuItem value={Height.Intermediate}>500</MenuItem>
              <MenuItem value={Height.Medium}>400</MenuItem>
              <MenuItem value={Height.Small}>300</MenuItem>
              <MenuItem value={Height.Anysize}>200/300 (Anysize)</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(1);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.height.length === 0}
            onClick={() => {
              setStage(3);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 3 && (
        <>
          <Typography fontWeight={"bold"}>Dog's Grade</Typography>
          <Typography marginTop={2}>
            This is the Kennel Club grade of the dog you are competing with. 🏆
          </Typography>
          <FormControl sx={{ marginTop: 2 }} fullWidth>
            <InputLabel id={`grade-label-${index}`}>Grade</InputLabel>
            <Select
              labelId={`grade-label-${index}`}
              id={`grade-select-${index}`}
              value={entry.grade}
              label="Grade"
              name="grade"
              onChange={(e) => handleChange(index, e)}
            >
              <MenuItem value={Grade.One}>Grade 1</MenuItem>
              <MenuItem value={Grade.Two}>Grade 2</MenuItem>
              <MenuItem value={Grade.Three}>Grade 3</MenuItem>
              <MenuItem value={Grade.Four}>Grade 4</MenuItem>
              <MenuItem value={Grade.Five}>Grade 5</MenuItem>
              <MenuItem value={Grade.Six}>Grade 6</MenuItem>
              <MenuItem value={Grade.Seven}>Grade 7</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(2);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.grade === 0}
            onClick={() => {
              setStage(8);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 4 && (
        <>
          <Typography fontWeight={"bold"}>Dog's Name</Typography>
          <Typography marginTop={2}>
            The Kennel Club Name of the dog you wish to run. 🐶
          </Typography>
          <Autocomplete
            options={accountInfo.dogs?.map((d) => d?.kc_name || "") || []}
            freeSolo
            value={entry.kennel_club_name || ""}
            inputValue={entry.kennel_club_name || ""}
            onInputChange={(event, newInputValue) => {
              if (newInputValue) {
                handleChange(index, {
                  target: { value: newInputValue, name: "kennel_club_name" },
                });
              }
            }}
            onChange={(event: any, newValue?: string | null) => {
              if (newValue) {
                handleChange(index, {
                  target: { value: newValue, name: "kennel_club_name" },
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                margin="dense"
                helperText="Dog's kennel club name"
                id="kennel_club_name"
              />
            )}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(0);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={
              !entry.kennel_club_name || entry.kennel_club_name?.length === 0
            }
            onClick={() => {
              setStage(5);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 5 && (
        <>
          <Typography fontWeight={"bold"}>Dog's KC Number</Typography>
          <Typography marginTop={2}>
            The Kennel Club Number of the dog you wish to run. 🔢
          </Typography>
          {accountInfo.dogs?.find((d) => d.kc_name === entry.kennel_club_name)
            ?.kc_number ? (
            <>
              <Typography marginTop={2}>
                We have your kennel club number and we believe it ends in:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {
                    accountInfo.dogs.find(
                      (d) => d.kc_name === entry.kennel_club_name
                    )?.kc_number
                  }
                </span>
              </Typography>
              <Typography marginTop={2}>
                If this is not correct, then please email matt@easyagility.co.uk
                with the updated kennel club name. 📝
              </Typography>
            </>
          ) : (
            <TextField
              fullWidth
              variant="standard"
              margin="dense"
              helperText="Dog's kennel club number"
              value={entry.kennel_club_number || ""}
              onChange={(e) => handleChange(index, e)}
              id="dog_name"
              name="kennel_club_number"
            />
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(4);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={
              !entry.kennel_club_name || entry.kennel_club_name?.length === 0
            }
            onClick={() => {
              setStage(6);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 6 && (
        <>
          <Typography fontWeight={"bold"}>Dog's Name</Typography>
          <Typography marginTop={2}>
            The Dog's date of birth, please note that the dog must be 18 months
            old to compete at this show. 🐶
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            margin="dense"
            helperText="Dog's date of birth, please express as dd/mm/yyyy, e.g 22/05/2016."
            value={entry.dob || ""}
            onChange={(e) => handleChange(index, e)}
            id="dob"
            name="dob"
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(5);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={
              !entry.dob ||
              entry.dob?.length === 0 ||
              entry.dob?.split("/").length !== 3
            }
            onClick={() => {
              setStage(8);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 7 && (
        <>
          <Typography fontWeight={"bold"}>Classes to enter</Typography>
          <Typography marginTop={2} marginBottom={2}>
            Please select which classes you want to run in. 👨‍🎓
          </Typography>
          <Box>
            <FormControl fullWidth>
              <Select
                labelId="class-label"
                id="class-select"
                value={
                  entry.classes?.length === 4
                    ? "All Classes"
                    : entry.classes.includes(152)
                    ? "Agility/Jumping"
                    : entry.classes.includes(154)
                    ? "Steeplechases Only"
                    : ""
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value === "All Classes") {
                    console.log("All Classes");
                    handleChange(index, {
                      target: {
                        value: [152, 153, 154, 155],
                        name: "classes",
                      },
                    });
                  } else if (e.target.value === "Agility/Jumping") {
                    handleChange(index, {
                      target: { value: [152, 153], name: "classes" },
                    });
                  } else if (e.target.value === "Steeplechases Only") {
                    handleChange(index, {
                      target: { value: [154, 155], name: "classes" },
                    });
                  }
                }}
              >
                <MenuItem value=""></MenuItem>
                {entry.height !== Height.Anysize && (
                  <MenuItem value="All Classes">All Classes</MenuItem>
                )}
                {entry.height !== Height.Anysize && (
                  <MenuItem value="Agility/Jumping">Agility/Jumping</MenuItem>
                )}
                <MenuItem value="Steeplechases Only">
                  Steeplechases Only
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <Box>
            {availableClasses.map((c) => (
              <Box marginTop={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={entry.classes.includes(c.id)}
                      disabled={
                        ((showId === 23 && entry.classes?.length > 2) ||
                          (showId === 24 && entry.classes?.length > 3)) &&
                        !entry.classes.includes(c.id)
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleAddClass(index, c.id);
                        } else {
                          handleRemoveClass(index, c.id);
                        }
                      }}
                      name={c.name}
                    />
                  }
                  label={`${c.name} - ${c.judge_name}`}
                />
              </Box>
            ))}
          </Box> */}

          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(3);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.classes.length === 0}
            onClick={() => {
              setStage(8);
            }}
          >
            Continue
          </Button>
        </>
      )}
      {stage === 8 && (
        <Box>
          <Typography fontWeight={"bold"}>Partnership Summary</Typography>
          <Typography marginTop={2}>
            Below is a summary of your partnership's entry. If you are not
            happy, please go back and edit the information, otherwise add
            another dog or proceed with your entry! 💨
          </Typography>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Typography marginTop={2}>
            <strong>Handler Name:</strong> {entry.handler_name}
          </Typography>
          <Typography marginTop={2}>
            <strong>Dog's Name:</strong> {entry.dog_name}
          </Typography>
          <Typography marginTop={2}>
            <strong>Dog's Height:</strong> {entry.height}
          </Typography>
          <Typography marginTop={2}>
            <strong>Dog's Grade:</strong> {entry.grade}
          </Typography>
          <Typography marginTop={2}>
            <strong>Classes Entered:&nbsp;</strong>
            {/* {entry.classes
              .map((c) => availableClasses?.find((ac) => ac.id === c)?.name)
              .join(", ")} */}
            Jumping, Steeplechase 1, Steeplechase 2
          </Typography>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(3);
            }}
          >
            Back
          </Button>
          {totalEntries > index + 1 ? (
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: 2,
                textTransform: "none",
                marginTop: 2,
                bgcolor: "#8f9c5d",
              }}
              disabled={entry.grade === 0}
              onClick={() => {
                setIndex(index + 1);
                setStage(0);
              }}
            >
              Next dog
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: 2,
                textTransform: "none",
                marginTop: 2,
                bgcolor: "#8f9c5d",
              }}
              disabled={entry.grade === 0}
              onClick={() => {
                addEntry();
                setIndex(index + 1);
                setStage(0);
              }}
            >
              Add another dog
            </Button>
          )}

          {totalEntries > 1 && index > 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: 2,
                textTransform: "none",
                marginTop: 2,
                bgcolor: "#8f9c5d",
              }}
              disabled={entry.grade === 0}
              onClick={() => {
                removeEntry(index);
                setIndex(index - 1);
              }}
            >
              Remove this dog
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.grade === 0}
            onClick={() => {
              setStage(13);
            }}
          >
            Proceed to Checkout
          </Button>
        </Box>
      )}
      {stage === 9 && (
        <Box>
          <Typography fontWeight={"bold"}>NFC Dogs</Typography>
          <Typography marginTop={2}>
            We now need to know if you have any dogs that you are bringing to
            the show who will not be competing. 🐶
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.grade === 0}
            onClick={() => {
              if (!entry.nfc_dogs || entry.nfc_dogs.length < 6) {
                handleChange(0, {
                  target: {
                    value: [
                      { name: "", dob: "" },
                      { name: "", dob: "" },
                      { name: "", dob: "" },
                      { name: "", dob: "" },
                      { name: "", dob: "" },
                      { name: "", dob: "" },
                    ],
                    name: "nfc_dogs",
                  },
                });
              }
              setTimeout(() => {
                setStage(10);
              }, 200);
            }}
          >
            Yes, I have NFC dogs
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={entry.grade === 0}
            onClick={() => {
              setStage(11);
            }}
          >
            No, I do not have NFC dogs
          </Button>
        </Box>
      )}
      {stage === 10 && entry.nfc_dogs && (
        <Box>
          <Typography fontWeight={"bold"}>NFC Dogs</Typography>
          <Typography marginTop={2}>
            Please include the name and date of birth of any dogs coming to the
            show who are NOT for competition. 🐶
          </Typography>

          <Grid container spacing={2}>
            {entries[0].nfc_dogs?.map((nfc, i) => (
              <Grid item xs={12} md={6} key={i}>
                <Typography marginTop={2}>Dog {i + 1}</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  margin="dense"
                  helperText="Dog's name"
                  value={nfc.name}
                  onChange={(e) => {
                    if (entries[0].nfc_dogs) {
                      const newNfc = [...entries[0].nfc_dogs];
                      newNfc[i].name = e.target.value;
                      handleChange(0, {
                        target: { value: newNfc, name: "nfc_dogs" },
                      });
                    }
                  }}
                  id="dog_name"
                  name="dog_name"
                />
                <TextField
                  fullWidth
                  variant="standard"
                  margin="dense"
                  helperText="Dog's date of birth"
                  value={nfc.dob}
                  onChange={(e) => {
                    if (entries[0].nfc_dogs) {
                      const newNfc = [...entries[0].nfc_dogs];
                      newNfc[i].dob = e.target.value;
                      handleChange(0, {
                        target: { value: newNfc, name: "nfc_dogs" },
                      });
                    }
                  }}
                  id="dob"
                  name="dob"
                />
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(9);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={
              !entry.dob ||
              entry.dob?.length === 0 ||
              entry.dob?.split("/").length !== 3
            }
            onClick={() => {
              if (showId === 23) {
                setStage(11);
              } else {
                setStage(12);
              }
            }}
          >
            Continue
          </Button>
        </Box>
      )}
      {stage === 11 && (
        <Box>
          <Typography fontWeight={"bold"}>Entry Tickets Postage</Typography>
          <Typography marginTop={2}>
            {showId === 23
              ? `To enter this show, you must have a entry tickets. There will be a
            small fee of £1.50 so that this can posted out to you. Please
            provide an address so that your pass can be sent out to you. 🚗`
              : `The Kennel Club requires an address for every entry, please provide the address in the text field below, thank you!`}
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            margin="dense"
            helperText="Address for entry"
            value={entries[0].address || ""}
            onChange={(e) => handleChange(0, e)}
            id="address"
            name="address"
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(9);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            disabled={
              !entry.dob ||
              entry.dob?.length === 0 ||
              entry.dob?.split("/").length !== 3
            }
            onClick={() => {
              setStage(12);
            }}
          >
            Continue
          </Button>
        </Box>
      )}
      {stage === 12 && (
        <Box>
          <Typography fontWeight={"bold"}>Kennel Club Declaration</Typography>
          <Typography marginTop={2}>
            By continuing below, I/we agree to adhere to the current Kennel Club
            Rules & Regulations and any future amendments regarding all matters
            related to canines overseen by the Kennel Club. This entry is made
            with the understanding that all current single or joint registered
            owners of the dog(s) in question have authorised this entry.
          </Typography>
          <Typography marginTop={2}>
            Additionally, I/we commit to following the regulations of this show,
            ensuring that no dog exhibiting symptoms of infectious or contagious
            diseases within 21 days prior to the show, or visibly suffering from
            a condition affecting its health or welfare, will be brought to the
            event.
          </Typography>
          <Typography marginTop={2}>
            {" "}
            I/we also affirm that, to the best of my/our knowledge, the dogs
            entered are not subject to disqualification under Kennel Club
            Agility Show Regulations.
          </Typography>{" "}
          <Typography marginTop={2} fontWeight={"bold"}>
            {" "}
            Kennel Club Registration
          </Typography>
          <Typography marginTop={2}>
            {" "}
            I/We acknowledge that, for competition entry, a dog must be
            registered in accordance with Kennel Club Rules and Regulations,
            either in the owner's name or with registration or a transfer
            thereof applied for.
          </Typography>
          <Typography marginTop={2} fontWeight={"bold"}>
            {" "}
            Measuring
          </Typography>
          <Typography marginTop={2}>
            {" "}
            I/We understand that dogs categorised as small, medium, and
            intermediate must undergo measurement procedures before competing,
            as outlined in Regulation H(1)(B)4.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              setStage(13);
            }}
          >
            Confirm and Continue
          </Button>
        </Box>
      )}
      {stage === 13 && (
        <Box>
          <Typography fontWeight={"bold"}>Entry Fees</Typography>
          <Typography marginTop={2}>
            With the information you have provided, the figures below will be
            your entry fees. You may either choose to pay by bank transfer or by
            card. A Show processing fee of 2.5% + 0.25p will be added to your
            total. YOU MUST PAY WITHIN 24 HOURS OF ENTERING OR YOUR ENTRY WILL
            BE DELETED.
          </Typography>
          <Typography marginTop={2} fontWeight="bold">
            Entry: £{total.toFixed(2)}
          </Typography>
          <Typography fontWeight="bold">
            Processing Fee: £{processingFee.toFixed(2)}
          </Typography>
          <Typography fontWeight="bold">
            Total: £{(total + processingFee).toFixed(2)}
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            margin="dense"
            helperText="If you have a discount code, please enter it here"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <br />
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            onClick={() => {
              preEnter();
              setStage(14);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              marginTop: 2,
              marginLeft: 2,
              bgcolor: "#8f9c5d",
            }}
            onClick={() => {
              setStage(8);
            }}
          >
            Back
          </Button>
        </Box>
      )}
      {stage === 14 && (
        <Box>
          {paid ? (
            <>
              <Typography fontWeight={"bold"}>
                Entry Received and Paid
              </Typography>
              <Typography marginTop={2}>
                Thank you for your entry, we have now received payment from you
                and there is nothing more to do. If you need to cancel your
                entry, please email Matt at matt@easyagility.co.uk or send a
                message on Whatsapp at 07984598308. Thank you! 🎉
              </Typography>
              <Typography marginTop={2}>
                {entries.map((e, i) => (
                  <Box key={index}>
                    <Typography marginTop={2} fontWeight={"bold"}>
                      {e.dog_name}
                    </Typography>
                    <Typography>
                      {e.classes
                        .map((c) => {
                          const classObj = showDetails.classes.find(
                            (ac) => ac.id === c
                          );
                          return classObj?.name;
                        })
                        .join(", ")}
                    </Typography>
                  </Box>
                ))}
              </Typography>
            </>
          ) : (
            <>
              <Typography fontWeight={"bold"}>Entry Fees</Typography>
              <Typography marginTop={2}>
                We have received your entry, and you should have an email now
                confirming your entry details. If you cannot find it then please
                check your junk email.
              </Typography>

              <Typography marginTop={2}>
                You can now choose to pay by bank transfer or by card, which you
                can do by clicking below, or you can edit your entry. YOU MUST
                PAY WITHIN 24 HOURS OF ENTERING OR YOUR ENTRY WILL BE DELETED.
              </Typography>

              <Grid container spacing={2}>
                {entries.map((e, i) => (
                  <Grid item xs={12} md={6} key={i}>
                    <Box
                      padding={2}
                      sx={{ borderColor: "#8f9c5d" }}
                      border={1}
                      marginTop={2}
                      display="flex"
                    >
                      <Typography marginTop={0.5}>{e.dog_name}</Typography>{" "}
                      <Button
                        onClick={() => {
                          setIndex(i);
                          setStage(0);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Grid>
                ))}
                <br />
              </Grid>
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    marginTop: 2,
                    bgcolor: "#8f9c5d",
                  }}
                  onClick={() => {
                    setStage(15);
                  }}
                >
                  Pay by card
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    marginTop: 2,
                    marginLeft: 2,
                    bgcolor: "#8f9c5d",
                  }}
                  onClick={() => {
                    setStage(16);
                  }}
                >
                  Pay by bank transfer
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
      {stage === 15 && (
        <Box>
          <Typography marginTop={2}>
            Please enter your card details below to pay for your entry. 🏦
          </Typography>
          <Typography marginBottom={2}>
            Total to pay:{" "}
            <span style={{ fontWeight: "bold" }}>
              £{(total + processingFee).toFixed(2)}
            </span>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              marginTop: 1,
              marginBottom: 2,
              bgcolor: "#8f9c5d",
            }}
            onClick={() => {
              setStage(14);
            }}
          >
            Back
          </Button>
          <CardPayment
            amount={(total + processingFee) * 100}
            setPaid={setPaid}
            paid={paid}
            reference={reference}
          />
        </Box>
      )}
      {stage === 16 && (
        <Box>
          <Typography marginTop={2}>
            To pay by bank transfer please send payment to the following bank
            account 🏦
          </Typography>
          <Typography marginBottom={2}>
            Total to pay:{" "}
            <span style={{ fontWeight: "bold" }}>
              £{(total + processingFee).toFixed(2)}
            </span>
          </Typography>
          <Typography marginTop={2}>Account Name: Easy Agility</Typography>
          <Typography>
            Reference: <span style={{ fontWeight: "bold" }}>{reference}</span>
          </Typography>
          <Typography>Account Number: 66835282</Typography>
          <Typography>Account Sort Code: 04-00-03</Typography>

          <Typography marginTop={2}>
            <span style={{ fontWeight: "bold" }}>
              Your payment reference is DIFFERENT for each show.
            </span>{" "}
            You must ensure that your payment reference matches the one given
            above, to avoid delays in payment processing. Thank you!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              marginTop: 2,
              bgcolor: "#8f9c5d",
            }}
            onClick={() => {
              setStage(14);
            }}
          >
            Back
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EntryComponent;
