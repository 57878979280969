import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const emailReg =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const Email = ({
  emailAddress,
  setEmailAddress,
  addEntry,
  getAccountInfo,
}: {
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
  addEntry: () => void;
  getAccountInfo: () => void;
}): JSX.Element => {
  const [showCode, setShowCode] = useState<boolean>(true);
  const [code, setCode] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");

  useEffect(() => {
    if (code === "MerryChristmas") {
      setShowCode(false);
    }
  }, [code]);

  return (
    <>
      {showCode ? (
        <Box>
          <Typography fontWeight={"bold"}>
            A code is required to enter this show, please enter it below! 🎄
          </Typography>
          <TextField
            value={code}
            placeholder="Enter Show Code Here"
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            id="code"
            variant="standard"
            margin="dense"
          />
        </Box>
      ) : (
        <Box>
          <Typography fontWeight={"bold"}>Email Address</Typography>
          <Typography marginTop={2}>
            We need this so that we can notify you of your entry! Please be
            careful to enter it correctly. 🙏
          </Typography>
          <TextField
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            fullWidth
            id="email"
            helperText="Email address"
            variant="standard"
            margin="dense"
          />
          <TextField
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            fullWidth
            id="email"
            helperText="Confirm your email address"
            variant="standard"
            margin="dense"
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", marginTop: 2, bgcolor: "#8f9c5d" }}
            disabled={
              emailAddress.length === 0 ||
              emailAddress !== confirmEmail ||
              !emailReg.test(emailAddress)
            }
            onClick={() => {
              getAccountInfo();
              addEntry();
            }}
          >
            Begin Entry
          </Button>
        </Box>
      )}
    </>
  );
};

export default Email;
